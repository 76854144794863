import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserGeolocatedCountry } from "../store/slice/userSlice";

function useGeoLocation() {
  const dispatch = useDispatch();

  useEffect(() => {
    fetch("/geolocation")
      .then((response) => response.json())
      .then((data) => {
        dispatch(setUserGeolocatedCountry(data.country.code));
      })
      .catch((error) => console.log(error));
  }, []);
}

export default useGeoLocation;
