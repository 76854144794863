import styled, { css } from "styled-components";
import {
  device,
  colors,
  transitions,
  main_theme,
  StyledFonts,
  fixedSidePadding,
  CancelOffLineHeightForEN,
} from "../Layout/theme";

export const TopWrapper = styled.div``;

export const TitleWrapper = styled.div`
  width: 100%;
  background: ${colors.white};
  color: ${colors.black};
  padding: 1.6rem 0 0.8rem;

  @media ${device.tablet} {
    padding: 1.6rem 0 1rem;
  }

  @media ${device.laptop} {
    padding-top: 0;
  }
`;

export const Title = styled.h3`
  width: 100%;
  ${StyledFonts.mainH2};

  @media ${device.tablet} {
    ${StyledFonts.mainH4};

    [lang="pl-pl"] & {
      font-size: clamp(3.2rem, 4vw, 3.2rem);
    }
  }

  @media ${device.laptop} {
    [lang="tr-tr"] .product-card-collector & {
      br {
        display: none;
      }
    }
  }

  @media ${device.laptopL} {
    [lang="pl-pl"] & {
      font-size: clamp(3.2rem, 4vw, 4.2rem);
    }

    [lang="tr-tr"] .product-card-collector & {
      br {
        display: inline-block;
      }
    }
  }

  ${CancelOffLineHeightForEN};
`;

export const AssetWrapper = styled.div``;

export const ProductDetailWrapper = styled.div`
  color: ${colors.black};
  background: ${colors.white};
  padding-top: 0.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    padding-top: 1rem;
  }
`;

export const ProductFeatureWrapper = styled.div`
  border-bottom: 1px solid ${colors.black};
  margin-bottom: 1rem;
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 1fr;
  padding: 1rem 0 2rem;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    padding: 1.5rem 0;
  }

  @media ${device.laptop} {
    grid-gap: 2rem;
    grid-template-columns: 2fr 3fr;
    min-height: 220px;

    [lang="cs-cz"] &,
    [lang="it-it"] &,
    [lang="vi-vn"] & {
      min-height: 240px;
    }

    [lang="ro-ro"] & {
      min-height: 260px;
    }

    [lang="pl-pl"] &,
    [lang="ru-ru"] &,
    [lang="tr-tr"] & {
      min-height: 270px;
    }
  }

  @media ${device.laptopM} {
    grid-template-columns: 1fr 1fr;
    min-height: 220px;

    [lang="es-mx"] &,
    [lang="it-it"] & {
      min-height: 250px;
    }

    [lang="vi-vn"] & {
      min-height: 260px;
    }

    [lang="cs-cz"] & {
      min-height: 220px;
    }
  }

  @media (min-width: 1300px) {
    [lang="vi-vn"] & {
      min-height: 220px;
    }
  }

  @media ${device.laptopL} {
    [lang="es-mx"] &,
    [lang="it-it"] &,
    [lang="ro-ro"] &,
    [lang="tr-tr"] &,
    [lang="vi-vn"] & {
      min-height: 220px;
    }

    [lang="pl-pl"] & {
      min-height: 240px;
    }

    [lang="ru-ru"] & {
      min-height: 260px;
    }
  }
`;

export const ProductFeatureList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.8rem;
  height: fit-content;

  /* hide 2nd digital item list on mobile */
  &.collector.desktop {
    display: none;
  }

  @media ${device.tablet} {
    margin-bottom: 1rem;

    &.collector.mobile {
      display: none;
    }
    &.collector.desktop {
      display: grid;
    }
  }
`;

export const ProductFeature = styled.li`
  display: list-item;
  list-style: square outside;
  margin-left: 1em;
  padding-inline-start: 1rem;
  text-align: left;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.3;

  @media ${device.tablet} {
    font-size: 1.2rem;
  }

  @media ${device.safari} {
    margin-left: 1.5em;
  }
`;

export const ProductCTA = styled.div`
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;

    [lang="fr-fr"] &,
    [lang="it-it"] &,
    [lang="pt-br"] & {
      grid-template-columns: 1fr;
    }
  }

  @media ${device.laptop} {
    [lang="es-es"] &,
    [lang="es-mx"] &,
    [lang="id-id"] &,
    [lang="it-it"] &,
    [lang="pt-br"] & {
      grid-template-columns: 2fr 3fr;
    }

    [lang="ru-ru"] &,
    [lang="ro-ro"] &,
    [lang="el-gr"] &,
    [lang="tr-tr"] & {
      grid-template-columns: 1fr;
    }
  }

  @media ${device.laptopM} {
    [lang="ru-ru"] & {
      grid-template-columns: 2fr 3fr;
    }

    [lang="el-gr"] & {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media ${device.laptopL} {
    [lang="es-es"] &,
    [lang="es-mx"] &,
    [lang="id-id"] &,
    [lang="pt-br"] &,
    [lang="ru-ru"] &,
    [lang="tr-tr"] & {
      grid-template-columns: 1fr 1fr;
    }

    [lang="fr-fr"] &,
    [lang="it-it"] & {
      grid-template-columns: 2fr 3fr;
    }
  }
`;

export const PriceWrapper = styled.div`
  padding: 1.4rem 0;
  display: flex;
  align-items: center;
  flex-grow: 1;

  @media ${device.tablet} {
    border-bottom: 1px solid ${colors.black};
  }
`;

export const Price = styled.h3`
  text-align: left;
  display: block;
  ${StyledFonts.mainH2};

  @media ${device.tablet} {
    ${StyledFonts.mainH4};
  }
`;

export const CtaWrapper = styled.div`
  a {
    height: 100%;
  }

  @media ${device.tablet} {
    border-bottom: 1px solid ${colors.black};
  }

  &.soldout {
    pointer-events: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
