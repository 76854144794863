import React, { useEffect, useRef, useState } from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import {
  VideoWrapper,
  StyledVideo,
  PlaceholderImageWrapper,
  PlacerholderImage,
  ControlText,
  VideoControlsWrapper,
  PlayControlWrapper,
  SoundControlWrapper,
} from "./style";

import VideoPlayIcon from "../../images/svg/video-play.svg";
import VideoPauseIcon from "../../images/svg/video-pause.svg";
import AudioMuteIcon from "../../images/svg/audio-off.svg";
import AudioPlayIcon from "../../images/svg/audio-on.svg";

interface Params {
  imgUrl: string;
  imgAlt?: string;
  videoUrl?: string;
  className?: string;
  fillUpContainer?: boolean;
  type?: "display-mobile" | "display-desktop"; // default, show at all times
}

// accept both image and video, if videoUrl is provide, render video
const Media: React.FC<Params> = ({ imgUrl, imgAlt, videoUrl, className, fillUpContainer, type }) => {
  const fillUpContainerClass = fillUpContainer ? "fill-up-container" : "";
  const customClass = className || "";
  const imgClass = videoUrl ? "position-absolute" : "";
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoPlayStatus, setVideoPlayStatus] = useState(false);
  const [videoSoundPlayingStatus, setVideoSoundPlayingStatus] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { language } = useI18next();
  const { t } = useTranslation("translations");
  const audioOnText = t("audio.on");
  const audioOffText = t("audio.off");
  const videoPlayText = t("video.play");
  const videoPauseText = t("video.pause");
  const [showAudioText, setShowAudioText] = useState<boolean>(false);
  const [showVideoText, setShowVideoText] = useState<boolean>(false);

  useEffect(() => {
    if (videoRef.current) {
      if (!videoRef.current.paused) {
        setVideoPlayStatus(true);
      }

      // Note need muted to allow autoplay... default is muted
      // if (!videoRef.current.muted) {
      //   setVideoSoundPlayingStatus(true);
      // }
    }
  }, [videoRef]);

  const toggleVideoPlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
      }
      videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause();
      setVideoPlayStatus(!videoPlayStatus);
    }
  };

  const toggleVideoSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setVideoSoundPlayingStatus(!videoSoundPlayingStatus);
    }
  };

  const responsiveClass = type == null ? "" : type;

  // NOTE: image reveal animation
  useEffect(() => {
    if (!containerRef?.current) return;

    const observerOptions = {
      threshold: 0.6,
      // rootMargin: "-10px 0px -10px 0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);
    observer.observe(containerRef.current);
  }, [containerRef]);

  const isAnimatedClass = true ? "animated-zoom" : "";

  return (
    <VideoWrapper
      ref={containerRef}
      className={`${customClass} ${fillUpContainerClass} video-wrapper ${responsiveClass}`}
    >
      {videoUrl ? (
        <>
          {/* Video */}
          <StyledVideo ref={videoRef} autoPlay loop playsInline muted poster={imgUrl} className={isAnimatedClass}>
            <source src={videoUrl} type="video/mp4"></source>
          </StyledVideo>

          {/* Controls */}
          <VideoControlsWrapper>
            <PlayControlWrapper
              onClick={toggleVideoPlay}
              className={`${!videoPlayStatus ? "is-paused" : ""} ga-video-toggle-btn ga-video-control-btn`}
              onMouseOver={() => setShowVideoText(true)}
              onMouseLeave={() => setShowVideoText(false)}
            >
              <VideoPlayIcon className="video-control-icon play" />
              <VideoPauseIcon className="video-control-icon position-absolute pause" />
            </PlayControlWrapper>
            <SoundControlWrapper
              onClick={toggleVideoSound}
              className={`${!videoSoundPlayingStatus ? "is-muted" : ""} ga-audio-toggle-btn ga-video-control-btn`}
              onMouseOver={() => setShowAudioText(true)}
              onMouseLeave={() => setShowAudioText(false)}
            >
              <AudioMuteIcon className="video-control-icon mute" />
              <AudioPlayIcon className="video-control-icon position-absolute unmute" />
            </SoundControlWrapper>

            <ControlText>
              {showVideoText && (videoPlayStatus ? videoPauseText : videoPlayText)}
              {showAudioText && (videoSoundPlayingStatus ? audioOffText : audioOnText)}
            </ControlText>
          </VideoControlsWrapper>
        </>
      ) : (
        <PlaceholderImageWrapper className={`${imgClass} ${isAnimatedClass}`}>
          {/* used normal img tag for simpler management */}
          <PlacerholderImage src={imgUrl} alt={imgAlt} />
        </PlaceholderImageWrapper>
      )}
    </VideoWrapper>
  );
};

export default Media;
