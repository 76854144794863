import styled from "styled-components";
import { device, colors, transitions, animations } from "../Layout/theme";

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &.display-mobile {
    display: block;
  }

  &.display-desktop {
    display: none;
  }

  @media ${device.tablet} {
    &.display-mobile {
      display: none;
    }

    &.display-desktop {
      display: block;
    }
  }

  &.hero-video {
    /* aspect-ratio: 9 / 16; */
    aspect-ratio: 9 / 13;

    @media ${device.tablet} {
      /* aspect-ratio: 16 / 9; */
      aspect-ratio: 18 / 9;
    }
  }
`;

export const StyledVideo = styled.video`
  height: 100%;
  width: 100%;

  .fill-up-container & {
    position: absolute;
    inset: 0;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  /* animation related */
  &.animated-zoom {
    transform: scale(1.15);
    transition: transform 1s ${transitions.imgCubicBezier1};

    .in-view & {
      transform: scale(1);
    }
  }
`;

export const PlaceholderImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  &.position-absolute {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* animation related */
  &.animated-zoom {
    img {
      transform: scale(1.15);
      transition: transform 1s ${transitions.imgCubicBezier1};

      .in-view & {
        transform: scale(1);
      }
    }
  }
`;

export const PlacerholderImage = styled.img`
  width: 100%;
  height: 100%;

  .fill-up-container & {
    object-fit: cover;
    object-position: center center;
  }
`;

export const PlayControlWrapper = styled.div`
  .play {
    opacity: 0;
  }
  .pause {
    opacity: 1;
  }

  &.is-paused {
    .play {
      opacity: 1;
    }
    .pause {
      opacity: 0;
    }
  }
`;

export const SoundControlWrapper = styled.div`
  .unmute {
    opacity: 0;
  }
  .mute {
    opacity: 1;
  }

  &.is-muted {
    .unmute {
      opacity: 1;
    }
    .mute {
      opacity: 0;
    }
  }
`;

export const ControlText = styled.span`
  padding-left: 1.6rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const VideoControlsWrapper = styled.div`
  position: absolute;
  left: 1.6rem;
  bottom: 1.6rem;
  display: flex;

  z-index: 5;

  @media ${device.tablet} {
    left: 2.4rem;
    bottom: 2.4rem;
  }

  ${PlayControlWrapper},
  ${SoundControlWrapper} {
    width: 30px;
    height: 30px;
    border: 1px solid ${colors.black};
    ${animations.fillLeftHoverEffect}
  }

  ${PlayControlWrapper} {
    border-right: 0;
  }

  /* relative to its controlWrapper */
  .video-control-icon {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;

    &.position-absolute {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
