import React from "react";
import { returnLinkTarget } from "../../helpers/utils";
import { ButtonWrapper, ButtonText } from "./style";

import ArrowDown from "../../images/svg/arrow-down.svg";

interface Params {
  text: string;
  link: string;
  showDownArrow?: boolean;
  gaTracking?: string;
}

const FillUpButton: React.FC<Params> = ({ text, link, showDownArrow, gaTracking }) => {
  const target = returnLinkTarget(link);

  return (
    <ButtonWrapper href={link} target={target} className={gaTracking}>
      <ButtonText>
        {text}
        {showDownArrow && <ArrowDown />}
      </ButtonText>
    </ButtonWrapper>
  );
};

export default FillUpButton;
