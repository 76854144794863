import React, { useRef, useEffect, useLayoutEffect } from "react";
import gsap from "gsap";
import { StaticImage } from "gatsby-plugin-image";
import {
  Wrapper,
  TopMarqueeWrapper,
  TopMarqueeInnerWrapper,
  TopMarqueeContent,
  BottomMarqueeWrapper,
  BottomMarqueeInnerWrapper,
  BottomMarqueeContent,
  MarqueeText,
  MarqueeImageWrapper,
} from "./style";

interface Params {
  text: string;
}

const Marquee: React.FC<Params> = ({ text }) => {
  const requiredTopContentAmount = 12;
  const arrayOfTopContent = Array.from(Array(requiredTopContentAmount).keys());

  const requiredBottomContentAmount = 12;
  const arrayOfBottomContent = Array.from(Array(requiredBottomContentAmount).keys());

  // following gsap-react specific setup https://greensock.com/react/
  // marquee animation
  const marqueeWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      let topMarqueeTween = gsap
        .to(".top-marquee-content", {
          xPercent: -100,
          repeat: -1,
          duration: 7,
          ease: "linear",
        })
        .totalProgress(0.5);

      let bottomMarqueeTween = gsap
        .to(".bottom-marquee-content", {
          xPercent: 100,
          repeat: -1,
          duration: 7,
          ease: "linear",
        })
        .totalProgress(0.5);

      let currentScroll = 0;
      let isScrollingDown = true;

      window.addEventListener("scroll", function () {
        if (window.pageYOffset > currentScroll) {
          isScrollingDown = true;
        } else {
          isScrollingDown = false;
        }

        gsap.to(topMarqueeTween, {
          timeScale: isScrollingDown ? -1 : 1,
        });
        gsap.to(bottomMarqueeTween, {
          timeScale: isScrollingDown ? -1 : 1,
        });

        currentScroll = window.pageYOffset;
      });
    }, marqueeWrapperRef);

    return () => ctx.revert();
  }, []);

  return (
    <Wrapper ref={marqueeWrapperRef} className="ga-marquee">
      {/* Top Marquee: Text + Logo */}
      <TopMarqueeWrapper>
        <TopMarqueeInnerWrapper>
          {arrayOfTopContent.map((el) => (
            <TopMarqueeContent className="top-marquee-content" key={el} aria-hidden="true">
              <MarqueeText> {text} </MarqueeText>
              <MarqueeImageWrapper className="top">
                <StaticImage
                  src="../../images/logo/logo-worlds-23-short-white.png"
                  alt=""
                  loading="eager"
                  aspectRatio={1 / 1}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </MarqueeImageWrapper>
            </TopMarqueeContent>
          ))}
        </TopMarqueeInnerWrapper>
      </TopMarqueeWrapper>

      {/* Bottom Marquee: Tagline */}
      <BottomMarqueeWrapper>
        <BottomMarqueeInnerWrapper>
          {arrayOfBottomContent.map((el) => (
            <BottomMarqueeContent className="bottom-marquee-content" key={el} aria-hidden="true">
              <MarqueeImageWrapper className="bottom">
                <StaticImage
                  src="../../images/logo/tagline-oneline-blue.png"
                  alt=""
                  loading="eager"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </MarqueeImageWrapper>
            </BottomMarqueeContent>
          ))}
        </BottomMarqueeInnerWrapper>
      </BottomMarqueeWrapper>
    </Wrapper>
  );
};

export default Marquee;
