import styled from "styled-components";
import { colors, StyledFonts, fixedSidePadding, gridSystem, main_theme, device } from "../Layout/theme";

export const EyebrowWrapper = styled.h3`
  ${StyledFonts.subheading1};
  ${gridSystem};
  color: ${colors.white};

  width: 100%;
  margin-bottom: 2rem;

  @media ${device.tablet} {
    /* margin: 2rem 0 3rem; */
    margin: 1rem 0 2.5rem;
  }

  &.splitted {
    max-width: 600px;
    display: flex;
    justify-content: space-between;

    @media ${device.tablet} {
      max-width: 690px;
    }
  }
`;

export const EyebrowLeft = styled.div`
  /* default: numbered  */
  grid-column: 1 / 2;

  @media ${device.tablet} {
    grid-column: 1 / 4;
  }

  .center & {
    grid-column: 1 / 3;

    @media ${device.tablet} {
      grid-column: 1 / 4;
    }
  }
`;

export const EyebrowCenter = styled.div`
  /* default: numbered  */
  grid-column: 2 / 6;

  @media ${device.tablet} {
    grid-column: 4 / 9;
  }

  .center & {
    grid-column: 3 / 6;

    @media ${device.tablet} {
      grid-column: 4 / 12;
    }
  }
`;

export const EyebrowRight = styled.div`
  text-align: right;

  /* default: numbered  */
  grid-column: 6 / 7;

  @media ${device.tablet} {
    grid-column: 9 / 13;
  }

  .center & {
    grid-column: 6 / 7;

    @media ${device.tablet} {
      grid-column: 12 / 13;
    }
  }
`;
