import styled, { css } from "styled-components";
import {
  device,
  colors,
  main_theme,
  transitions,
  StyledFonts,
  gridSystem,
  fixedSidePadding,
  CancelOffLineHeightForEN,
} from "../Layout/theme";

export const Wrapper = styled.div`
  ${fixedSidePadding};
  ${gridSystem};
  grid-gap: 0px;
  padding-top: 1.2rem;
  /* padding-bottom: 1.2rem; */

  @media ${device.tablet} {
    grid-gap: 0;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`;

export const ImageWrapper = styled.div`
  display: none;
  overflow: hidden;

  @media ${device.tablet} {
    display: block;
    grid-column: 1 / 5;
    margin-right: 10px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  /* animation related */
  &.animated-zoom {
    transform: scale(1.15);
    transition: transform 1s ${transitions.imgCubicBezier1};

    .in-view & {
      transform: scale(1);
    }
  }
`;

export const ContentWrapper = styled.div`
  grid-column: 1 / 7;

  .alert-message-wrapper {
    div:first-child {
      display: none;
    }
    p {
      padding-left: 0;
    }
  }

  @media ${device.tablet} {
    grid-column: 1 / 13;

    .alert-message-wrapper {
      margin-bottom: -1rem;
      margin-top: 0.5rem;

      div:first-child {
        display: grid;
      }
      p {
        padding-left: 0.5rem;
      }
    }
  }

  @media ${device.tablet} {
    grid-column: 5 / 13;
  }

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
  }
`;

export const TitleWrapper = styled.div`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
  }
`;

export const Title = styled.h2`
  ${StyledFonts.mainH2};
  width: 100%;
  display: inline-block;
  line-height: 1;
  margin-bottom: 1.8rem;
  margin-top: -0.06em;

  @media ${device.tablet} {
    ${StyledFonts.mainH3};
    margin-bottom: clamp(3.6rem, 4vw, 5.2rem);

    [lang="ru-ru"] & {
      margin-bottom: 12px;
    }
  }

  ${CancelOffLineHeightForEN};
`;

export const CTAWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 1.2rem;

  .collector & {
    border-bottom: 1px solid ${colors.white};
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0;

    .collector & {
      border-bottom: 0;
    }

    [lang="fr-fr"] &,
    [lang="tr-tr"] &,
    [lang="vi-vn"] & {
      grid-template-columns: 4fr 5fr;
    }

    [lang="ru-ru"] &,
    [lang="ro-ro"] & {
      grid-template-columns: 1fr 2fr;
    }
  }

  @media ${device.laptop} {
    grid-template-columns: 3fr 2fr;

    [lang="ro-ro"] & {
      grid-template-columns: 1fr 360px;
    }

    [lang="es-es"] &,
    [lang="es-mx"] &,
    [lang="id-id"] &,
    [lang="it-it"] &,
    [lang="pt-br"] & {
      grid-template-columns: 5fr 4fr;
    }
  }

  @media ${device.laptopM} {
    grid-template-columns: 2fr 1fr;

    [lang="fr-fr"] &,
    [lang="ru-ru"] &,
    [lang="tr-tr"] &,
    [lang="vi-vn"] & {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (min-width: 1340px) {
    [lang="ru-ru"] & {
      grid-template-columns: 3fr 2fr;
    }
  }

  @media ${device.desktop} {
    [lang="es-es"] &,
    [lang="es-mx"] &,
    [lang="id-id"] &,
    [lang="pt-br"] &,
    [lang="ro-ro"] &,
    [lang="tr-tr"] & {
      grid-template-columns: 2fr 1fr;
    }
  }
`;

export const Price = styled.h2`
  ${StyledFonts.mainH2};
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 1.4rem 0;

  @media ${device.tablet} {
    ${StyledFonts.mainH4};
    border-bottom: 1px solid ${colors.white};
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  @media ${device.tablet} {
    border-bottom: 1px solid ${colors.white};

    a {
      height: 100%;
    }
  }

  @media ${device.laptop} {
    a {
      border-top: 0;
    }
  }
`;
