import React from "react";
import { Wrapper, OverlayText } from "./style";
import Media from "../Media";

interface Params {
  imgUrl: string;
  mobileImgUrl: string;
  imgAlt?: string;
  videoUrl?: string;
  textTopLeft?: string;
  textTopRight?: string;
  textBottomLeft?: string;
  textBottomRight?: string;
}

const FullMediaSeparator: React.FC<Params> = ({
  imgUrl,
  mobileImgUrl,
  imgAlt,
  videoUrl,
  textTopLeft = "",
  textTopRight = "",
  textBottomLeft = "",
  textBottomRight = "",
}) => {
  return (
    <Wrapper className="ga-image-separator">
      {/* original design deco text */}
      <OverlayText className="top-left"> {textTopLeft} </OverlayText>
      <OverlayText className="top-right"> {textTopRight} </OverlayText>
      <OverlayText className="bottom-left"> {textBottomLeft} </OverlayText>
      <OverlayText className="bottom-right"> {textBottomRight} </OverlayText>

      {/* desktop */}
      <Media imgUrl={imgUrl} imgAlt={imgAlt} videoUrl={videoUrl} type="display-desktop" />
      {/* mobile */}
      <Media imgUrl={mobileImgUrl} imgAlt={imgAlt} videoUrl={videoUrl} type="display-mobile" />
    </Wrapper>
  );
};

export default FullMediaSeparator;
