import styled from "styled-components";
import {
  device,
  colors,
  StyledFonts,
  fixedSidePadding,
  gridSystem,
  main_theme,
  transitions,
  CancelOffLineHeightForEN,
} from "../Layout/theme";

export const Wrapper = styled.div`
  ${fixedSidePadding};
  padding-top: ${main_theme.paddingMobileSectionTop};
  width: 100%;
  display: flex;
  flex-direction: column;
  /* scroll-margin-top: ${main_theme.topbar_height}; */
  /* scroll-margin-top: -1.5rem; */
  scroll-margin-top: 3.5rem;

  .video-wrapper {
    aspect-ratio: 5 / 6;
    max-height: 50vh;
  }

  @media ${device.tablet} {
    padding-top: ${main_theme.paddingDesktopSectionTop};
  }

  @media ${device.laptop} {
    .video-wrapper {
      max-height: none;
      aspect-ratio: auto;
    }
  }
`;

export const Title = styled.h2`
  ${StyledFonts.mainH2};
  margin-bottom: 1rem;
  text-transform: uppercase !important;

  ${CancelOffLineHeightForEN};

  [lang="ja-jp"] & {
    margin-bottom: 0.7rem;
  }

  @media ${device.tablet} {
    [lang="pl-pl"] & {
      font-size: clamp(6rem, 6.4vw, 8.6rem);
    }
  }
`;

export const BottomWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "control"
    "info";
  grid-gap: 5px;
  padding-bottom: 1rem;

  @media ${device.tablet} {
    grid-gap: 10px;
    padding: 1rem 0;
    grid-template-columns: 4fr 1fr;
    grid-template-areas: "info control";
    align-items: stretch;
    overflow: hidden;
  }
`;

export const ProductInfo = styled.div`
  grid-area: info;
  width: 100%;
  border-bottom: 1px solid ${colors.white};
  padding: 1.6rem 0 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.tablet} {
    padding: 1.5rem 1.5rem 0 0;
    column-gap: 2.5rem;
    column-count: 2;
  }

  @media ${device.laptop} {
    min-height: 190px;
    padding: 1.5rem 16% 0 0;
  }
`;

export const ProductColumnWrapper = styled.div`
  width: 100%;
  padding-bottom: 3rem;

  @media ${device.tablet} {
    padding-bottom: 3.2rem;
    column-gap: 2.5rem;
    column-count: 2;
  }

  @media (min-width: 1800px) {
    column-count: auto;
    column-width: 300px;
    padding-right: 20%;
  }
`;

export const ProductDescription = styled.p`
  text-align: justify;
`;

export const DigitalEditionDisclaimer = styled.p`
  font-size: 1rem !important;
  padding-bottom: 1.5rem;

  &:before {
    content: "*";
    margin-right: 0.5rem;
  }

  @media ${device.laptop} {
    font-size: 1rem !important;
  }
`;

export const CarouselWrapper = styled.div`
  width: 100%;

  .swiper {
    padding-bottom: 40px;

    .swiper-pagination {
      text-align: left;
      pointer-events: all !important;
    }

    .swiper-pagination-bullet {
      border-radius: 0;
      background: transparent;
      border: 1px solid ${colors.white};
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: ${colors.white};
      }
    }

    .swiper-slide {
      img {
        transform: scale(1.15);
        transition: transform 1s ${transitions.imgCubicBezier1};
      }

      &.swiper-slide-active img {
        transform: scale(1);
      }
    }
  }

  @media ${device.tablet} {
    .swiper {
      padding-bottom: 40px;
      margin-bottom: -40px;

      .swiper-pagination {
        text-align: right;
      }
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
    }
  }
`;

export const SlideImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .video-wrapper {
    aspect-ratio: 3 / 2;
  }

  @media ${device.tablet} {
    .video-wrapper {
      aspect-ratio: unset;
    }
  }
`;

export const SlideImage = styled.img`
  width: 100%;
`;

export const CarouselControlWrapper = styled.div`
  grid-area: control;
  height: 0;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    height: auto;
    border-bottom: 1px solid ${colors.white};
  }
`;

export const CarouselControlSpaceFiller = styled.div`
  flex-grow: 1;
`;
