import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { colors, transitions, device } from "../Layout/theme";
import WorldsIcon from "../../images/logo/logo-worlds-23-white.png";

interface Params {
  className?: string;
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  z-index: 99999999999;
  display: grid;
  place-items: center;
  background: ${colors.black};
  clip-path: inset(0 0 0 0);
  transition: ${transitions.allCubicBezier1};
  display: none;

  @media ${device.tablet} {
    display: block;
  }

  &.hide {
    clip-path: inset(0 100% 0 0);
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LoadInAnimation: React.FC<Params> = () => {
  const videoWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!videoWrapperRef) return;

    setTimeout(() => {
      videoWrapperRef.current?.classList.add("hide");
    }, 600);
  }, [videoWrapperRef]);

  return (
    <Wrapper ref={videoWrapperRef}>
      <StyledVideo autoPlay playsInline muted>
        <source src="/assets/loading-animation.mp4" type="video/mp4"></source>
      </StyledVideo>
    </Wrapper>
  );
};

export default LoadInAnimation;
