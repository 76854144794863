import React, { useEffect, useState, useCallback } from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import {
  Wrapper,
  Title,
  BottomWrapper,
  ProductInfo,
  ProductColumnWrapper,
  ProductDescription,
  DigitalEditionDisclaimer,
  CarouselWrapper,
  SlideImageWrapper,
  SlideImage,
  CarouselControlWrapper,
  CarouselControlSpaceFiller,
} from "./style";
import Eyebrow from "../Eyebrow";
import Media from "../Media";
// cursor
import { CursorStates } from "../../helpers/constant";
import { useAppDispatch } from "../../store/index";
import { setCursorState } from "../../store/slice/cursorSlice";
// swiper lib
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, FreeMode, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

interface Params {
  productType: "collector" | "digital"; // object key for querying data
}

const ProductOverview: React.FC<Params> = ({ productType }) => {
  const { t } = useTranslation("translations");

  const localizedInfo = {
    eyebrowLeft: t(`productOverview.${productType}.eyebrow.left`),
    eyebrowCenter: t(`productOverview.${productType}.eyebrow.center`),
    eyebrowRight: t(`productOverview.${productType}.eyebrow.right`),
    title: t(`productOverview.${productType}.title`),
    image1_alt: t(`productOverview.${productType}.image.alt`),
    description: t(`productOverview.${productType}.description`),
  };

  // pulling images
  const productOverviewImages = {
    collector: [
      `/assets/product-overview/collector-edition-1.jpg`,
      `/assets/product-overview/collector-edition-2.jpg`,
      `/assets/product-overview/collector-edition-3.jpg`,
    ],
    digital: [
      `/assets/product-overview/digital-edition-1.jpg`,
      `/assets/product-overview/digital-edition-2.jpg`,
      `/assets/product-overview/digital-edition-3.jpg`,
    ],
  };
  const productOverviewMobileImages = {
    collector: [
      `/assets/product-overview/mobile/collector-edition-1.jpg`,
      `/assets/product-overview/mobile/collector-edition-2.jpg`,
      `/assets/product-overview/mobile/collector-edition-3.jpg`,
    ],
    digital: [
      `/assets/product-overview/mobile/digital-edition-1.jpg`,
      `/assets/product-overview/mobile/digital-edition-2.jpg`,
      `/assets/product-overview/mobile/digital-edition-3.jpg`,
    ],
  };
  const imageSlides = productOverviewImages[productType];

  // cursor logic
  const dispatch = useAppDispatch();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isOnHover, setIsOnHover] = useState<boolean>(false);
  const lastSlideIndex = imageSlides.length - 1 || 0;

  // onMouseOver Carousel
  const updateCursorStateOnEnter = useCallback(() => {
    setIsOnHover(true);
    let cursorState = currentIndex == lastSlideIndex ? CursorStates.DRAG_RIGHT : CursorStates.DRAG_LEFT;
    dispatch(setCursorState(cursorState));
  }, [currentIndex]);

  // onMouseLeave Carousel
  const resetCursorDefault = useCallback(() => {
    setIsOnHover(false);
    dispatch(setCursorState(CursorStates.DEFAULT));
  }, [CursorStates]);

  // on currentIndex of Carousel slide changes
  useEffect(() => {
    if (currentIndex == lastSlideIndex && isOnHover) {
      dispatch(setCursorState(CursorStates.DRAG_RIGHT));
    }

    if (currentIndex < lastSlideIndex && isOnHover) {
      dispatch(setCursorState(CursorStates.DRAG_LEFT));
    }
  }, [currentIndex]);

  // google analytics
  const gaSectionClass = `ga-${productType}-edition-overview ga-overview`;
  const gaSliderClass = `ga-${productType}-edition-overview-slider ga-overview-slider ga-slider`;

  return (
    <Wrapper id={`${productType}-edition-overview`} className={gaSectionClass}>
      <Eyebrow
        textLeft={localizedInfo.eyebrowLeft}
        textCenter={localizedInfo.eyebrowCenter}
        textRight={localizedInfo.eyebrowRight}
      />
      <Title>{localizedInfo.title}</Title>

      {imageSlides && (
        <CarouselWrapper>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            onActiveIndexChange={(element) => setCurrentIndex(element.activeIndex)}
            modules={[Pagination, FreeMode, Mousewheel]}
            grabCursor={true}
            edgeSwipeThreshold={10}
            mousewheel={{
              forceToAxis: true,
            }}
            className={gaSliderClass}
          >
            {imageSlides.map((image, index) => (
              <SwiperSlide key={index} onMouseEnter={updateCursorStateOnEnter} onMouseLeave={resetCursorDefault}>
                <SlideImageWrapper>
                  {/* desktop */}
                  <Media
                    imgUrl={image}
                    imgAlt={t(`productOverview.${productType}.image_${index}.alt`) || ""}
                    fillUpContainer={true}
                    type="display-desktop"
                  />
                  {/* mobile */}
                  <Media
                    imgUrl={productOverviewMobileImages[productType][index]}
                    imgAlt={t(`productOverview.${productType}.image_${index}.alt`) || ""}
                    fillUpContainer={true}
                    type="display-mobile"
                  />
                </SlideImageWrapper>
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselWrapper>
      )}

      <BottomWrapper>
        <ProductInfo>
          <ProductColumnWrapper>
            <ProductDescription>{parse(localizedInfo.description)}</ProductDescription>
          </ProductColumnWrapper>
          <DigitalEditionDisclaimer>{t("faq.qna.q5.answer")}</DigitalEditionDisclaimer>
        </ProductInfo>
        <CarouselControlWrapper>
          <CarouselControlSpaceFiller />
        </CarouselControlWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

export default ProductOverview;
