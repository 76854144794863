import styled from "styled-components";
import { device, colors, StyledFonts } from "../Layout/theme";

// variables
const topMarqueeOffset = "20vw"; // half of the height
const topMarqueeMoveInitial = `calc(-25% + ${topMarqueeOffset})`;
const topMarqueeMoveFinal = `calc(-50% + ${topMarqueeOffset})`;
const bottomMarqueeOffset = "10vw";
const bottomMarqueeMoveInitial = `calc(-25% + ${bottomMarqueeOffset})`;
const bottomMarqueeMoveFinal = `calc(-50% + ${bottomMarqueeOffset})`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const TopMarqueeWrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${colors.blue};
  color: ${colors.white};
  overflow: hidden;
`;

export const TopMarqueeInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: auto;
  width: fit-content;
  padding: 1.6rem 0;
  /* animation: marquee 4s linear infinite; */
  /* transform: translate3d(${topMarqueeMoveInitial}, 0, 0); */

  @keyframes marquee {
    0% {
      transform: translate3d(${topMarqueeMoveInitial}, 0, 0);
    }

    100% {
      transform: translate3d(${topMarqueeMoveFinal}, 0, 0);
    }
  }
`;

export const TopMarqueeContent = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, auto);
  flex-shrink: 0;
  gap: 1.5rem;
  padding-right: 1.5rem;

  @media ${device.tablet} {
    gap: 2.5rem;
    padding-right: 2.5rem;
  }

  @media ${device.laptop} {
    gap: 4rem;
    padding-right: 4rem;
  }
`;

export const MarqueeText = styled.h6`
  ${StyledFonts.mainH7};
  display: inline-block;
  width: fit-content;
  white-space: nowrap;
  text-transform: uppercase !important;
`;

export const MarqueeImageWrapper = styled.div`
  display: grid;
  place-items: center;
  height: fit-content;

  &.top {
    width: 2.2rem;
    @media ${device.tablet} {
      /* need to match with font size */
      width: clamp(3rem, 4.2vw, 5rem);
    }
  }

  &.bottom {
    width: 15rem;
  }
`;

export const BottomMarqueeWrapper = styled.div`
  width: 100%;
  background: ${colors.white};
  color: ${colors.blue};
`;

export const BottomMarqueeInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0;
  /* animation: marquee 8s linear reverse infinite; */
  /* transform: translate3d(${bottomMarqueeMoveInitial}, 0, 0); */

  @keyframes marquee {
    0% {
      transform: translate3d(${bottomMarqueeMoveInitial}, 0, 0);
    }

    100% {
      transform: translate3d(${bottomMarqueeMoveFinal}, 0, 0);
    }
  }
`;

export const BottomMarqueeContent = styled.div`
  padding-right: 3.5rem;

  @media ${device.tablet} {
    padding-right: 9rem;
  }
`;
