import React, { useEffect, useState, useCallback, useRef } from "react";
import parse from "html-react-parser";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import {
  Wrapper,
  EyebrowWrapper,
  CarouselWrapper,
  CardContainer,
  CardImageWrapper,
  CardImage,
  CardContentContainer,
  CardTitle,
  CardDescriptionWrapper,
  CardDescription,
  CardListSectionWrapper,
  CardListWrapper,
  CardListTitle,
  CarouselBackground,
  BlackFiller,
} from "./style";
import Eyebrow from "../Eyebrow";
// cursor
import { CursorStates } from "../../helpers/constant";
import { useAppDispatch } from "../../store/index";
import { setCursorState } from "../../store/slice/cursorSlice";
// swiper lib, go back to package version: 10.1.0 if swiper/react not well supported in future
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, FreeMode, Mousewheel } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
// gsap
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

interface Params {
  productType: "collector" | "digital"; // object key for querying data
}

const ProductFeatures: React.FC<Params> = ({ productType }) => {
  const { t } = useTranslation("translations");

  const localizedInfo = {
    eyebrowLeft: t(`productFeatures.${productType}.eyebrow.left`),
    eyebrowCenter: t(`productFeatures.${productType}.eyebrow.center`),
    eyebrowRight: t(`productFeatures.${productType}.eyebrow.right`),
  };

  // moved image path here, hopefully the products in each langauage is the same, or else we may need to move the image path back to translation json for individual language edit
  const featureImages = {
    collector: [
      `/assets/product-feature/collector/item-1.jpg`,
      `/assets/product-feature/collector/item-2.jpg`,
      `/assets/product-feature/collector/item-3.jpg`,
      `/assets/product-feature/collector/item-4.jpg`,
      `/assets/product-feature/collector/item-5.jpg`,
    ],
    digital: [
      `/assets/product-feature/digital/item-1.jpg`,
      `/assets/product-feature/digital/item-2.jpg`,
      `/assets/product-feature/digital/item-3.jpg`,
      `/assets/product-feature/digital/item-4.jpg`,
      `/assets/product-feature/digital/item-5.jpg`,
      `/assets/product-feature/digital/item-6.jpg`,
      `/assets/product-feature/digital/item-7.jpg`,
    ],
  };

  // filter feature carousel slides ----------
  const getCarouselSlides = (slideTotalNumber: number) => {
    const carouselSlides = [];

    for (let i = 1; i <= Number(slideTotalNumber); i++) {
      let currentSlide = {
        image: featureImages[productType][i - 1],
        imageAlt: t(`productFeatures.${productType}.carousel.slide_${i}.image.alt`) || "",
        title: t(`productFeatures.${productType}.carousel.slide_${i}.title`),
        description: t(`productFeatures.${productType}.carousel.slide_${i}.description`),
        itemList: [] as any[],
        titleCount: 0,
      };

      // check if listinfo exists
      let x = 1;
      let hasItemInfo = true;
      do {
        let listTitle = t(`productFeatures.${productType}.carousel.slide_${i}.list_${x}.title`);
        let itemlist = t(`productFeatures.${productType}.carousel.slide_${i}.list_${x}.itemlist`);
        let hasListTitle = !listTitle.startsWith("productFeatures");
        let hasItemList = !itemlist.startsWith("productFeatures");
        hasItemInfo = hasListTitle || hasItemList;

        if (hasItemInfo) {
          let listObject = {
            title: hasListTitle ? listTitle : "",
            items: hasItemList ? itemlist.split(";") : [],
          };
          currentSlide["itemList"].push(listObject);
          if (hasListTitle) currentSlide["titleCount"]++;
          x++;
        }
      } while (hasItemInfo);

      carouselSlides.push(currentSlide);
    }
    return carouselSlides;
  };
  const slideTotalNumber = featureImages[productType].length || 0;
  const localizedSlides = getCarouselSlides(slideTotalNumber);

  // cursor logic ----------
  const dispatch = useAppDispatch();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isOnHover, setIsOnHover] = useState<boolean>(false);
  const halfIndex = Math.floor(slideTotalNumber / 2) || slideTotalNumber;

  // onMouseOver Carousel
  const updateCursorState = useCallback(() => {
    setIsOnHover(true);

    let cursorState = currentIndex >= halfIndex ? CursorStates.DRAG_RIGHT : CursorStates.DRAG_LEFT;
    dispatch(setCursorState(cursorState));
  }, [currentIndex]);

  // onMouseLeave Carousel
  const resetCursorDefault = useCallback(() => {
    setIsOnHover(false);
    dispatch(setCursorState(CursorStates.DEFAULT));
  }, [CursorStates]);

  // on currentIndex of Carousel slide changes
  useEffect(() => {
    if (currentIndex >= halfIndex && isOnHover) {
      dispatch(setCursorState(CursorStates.DRAG_RIGHT));
    }

    if (currentIndex < halfIndex && isOnHover) {
      dispatch(setCursorState(CursorStates.DRAG_LEFT));
    }
  }, [currentIndex]);

  // make background black height same as image height ----------
  const [blackFillerHeight, setBlackFillerHeight] = useState("auto");
  const updateBlackFillerHeight = () => {
    const slideImage = document.querySelector(".slide-image") as Element;
    const slideImageHeight = window.getComputedStyle(slideImage).height;
    const height = `calc(${slideImageHeight} - 12%)`;
    if (slideImage) setBlackFillerHeight(height);
  };

  useEffect(() => {
    // set to same height
    window.addEventListener("resize", updateBlackFillerHeight);
    updateBlackFillerHeight();

    return () => {
      window.removeEventListener("resize", updateBlackFillerHeight);
    };
  }, []);

  // google analytics
  const gaSectionClass = `ga-${productType}-edition-feature-section ga-feature-section`;
  const gaSliderClass = `ga-collector-edition-feature-slider ga-feature-slider ga-slider`;

  // animation
  const featureWrapper = useRef<HTMLDivElement>(null);
  useEffect(() => {
    gsap.set(`.${productType}-features .slide-image`, {
      scale: 1.15,
    });
    const introAnimation = gsap.timeline({ paused: true });

    introAnimation.to(`.${productType}-features .slide-image`, {
      scale: 1,
      duration: 0.8,
      stagger: 0.18,
      ease: "power3.inOut",
    });

    const introRevealOne = ScrollTrigger.create({
      // markers: true,
      animation: introAnimation,
      trigger: featureWrapper.current,
      start: "top center",
      end: "center center",
    });
  }, []);

  return (
    <Wrapper className={`${gaSectionClass} ${productType}-features`} ref={featureWrapper}>
      <EyebrowWrapper>
        <Eyebrow
          textLeft={localizedInfo.eyebrowLeft}
          textCenter={localizedInfo.eyebrowCenter}
          textRight={localizedInfo.eyebrowRight}
        />
      </EyebrowWrapper>

      {/* Carousel Component */}
      {localizedSlides && (
        <CarouselWrapper className={`type-${productType}`}>
          <Swiper
            modules={[Scrollbar, FreeMode, Mousewheel]}
            spaceBetween={5}
            slidesPerView={"auto"}
            scrollbar={{
              draggable: true,
            }}
            grabCursor={true}
            freeMode={{
              enabled: true,
              sticky: true,
            }}
            onActiveIndexChange={(element) => setCurrentIndex(element.activeIndex)}
            breakpoints={{
              768: {
                spaceBetween: 10,
              },
            }}
            mousewheel={{
              forceToAxis: true,
            }}
            className={gaSliderClass}
          >
            {localizedSlides.map((slide, index) => (
              <SwiperSlide
                key={index}
                className={index == localizedSlides.length - 1 ? "last-slide" : ""}
                onMouseEnter={updateCursorState}
                onMouseLeave={resetCursorDefault}
              >
                <CardContainer>
                  {/* Image */}
                  <CardImageWrapper>
                    <CardImage src={slide.image} alt={slide.imageAlt} className="slide-image" />
                  </CardImageWrapper>

                  {/* Text content */}
                  <CardContentContainer>
                    <CardTitle>{parse(slide.title)}</CardTitle>
                    <CardDescriptionWrapper>
                      <CardDescription>{slide.description}</CardDescription>

                      {/* bottom list section */}
                      {slide.itemList.length > 0 && (
                        <CardListSectionWrapper className={slide.titleCount > 1 ? "has-title" : ""}>
                          {slide.itemList.map((list, i) => (
                            <CardListWrapper key={`list-${i}`}>
                              {list.title.length > 0 && <CardListTitle>{list.title}</CardListTitle>}
                              {list.items &&
                                list.items.map((item: string, x: number) => <li key={`item-${x}`}>{item}</li>)}
                            </CardListWrapper>
                          ))}
                        </CardListSectionWrapper>
                      )}
                    </CardDescriptionWrapper>
                  </CardContentContainer>
                </CardContainer>
              </SwiperSlide>
            ))}
          </Swiper>
          <CarouselBackground>
            <BlackFiller className="black-filler" style={{ height: blackFillerHeight }} />
          </CarouselBackground>
        </CarouselWrapper>
      )}
    </Wrapper>
  );
};

export default ProductFeatures;
