import styled from "styled-components";
import { colors, transitions, device } from "../Layout/theme";

export const Wrapper = styled.div`
  width: 100%;
`;

export const ModalLink = styled.a`
  color: ${colors.white};
  text-decoration: underline !important;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
`;
