import styled, { css } from "styled-components";
import { device, colors, main_theme, transitions, StyledFonts, gridSystem, fixedSidePadding } from "../Layout/theme";
import faqBgTexture from "../../images/faq-bg-texture.png";

export const Wrapper = styled.div`
  /* scroll-margin-top: ${main_theme.riotbar_height}; */
  /* scroll-margin-top: 0; */
  scroll-margin-top: 3.5rem;
`;

export const TitleWrapper = styled.div`
  ${gridSystem};
  ${fixedSidePadding};
  padding-top: 4.5rem;
  margin: 2rem 0;
  background: ${colors.black};
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: url(${faqBgTexture});
    background-size: cover;
    width: 100%;
  }

  @media ${device.tablet} {
    padding-top: clamp(6rem, 6vw, 10rem);
  }
`;

export const Title = styled.h2`
  ${StyledFonts.mainH3};
  font-size: 2.8rem;
  line-height: 0.96 !important;
  grid-column: 1 / 7;
  z-index: 2;
  display: flex;
  align-items: end;

  @media ${device.tablet} {
    font-size: clamp(3.2rem, 4vw, 6rem);
    grid-column: 1 / 12;
  }

  @media ${device.laptop} {
    grid-column: 1 / 9;
  }
`;

export const Subtitle = styled.h4`
  ${StyledFonts.subheading1};
  display: none;
  z-index: 2;

  @media ${device.laptop} {
    display: flex;
    align-items: end;
    justify-content: end;
    grid-column: 9 / 13;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.white};

  a {
    text-decoration: underline;
  }
`;
