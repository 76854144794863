import styled from "styled-components";
import { device, colors, StyledFonts, fixedSidePadding, main_theme } from "../Layout/theme";

export const Wrapper = styled.div`
  padding-top: ${main_theme.paddingMobileSectionTop};
  overflow: hidden;

  @media ${device.tablet} {
    padding-top: ${main_theme.paddingDesktopSectionTop};
  }
`;

export const EyebrowWrapper = styled.div`
  ${fixedSidePadding};
`;

export const CarouselWrapper = styled.div`
  ${fixedSidePadding};
  position: relative;

  .swiper {
    overflow: visible;
    padding-bottom: 5rem;
  }

  .swiper-wrapper {
    align-items: stretch;
    background: transparent;
  }

  .swiper-slide {
    position: relative;
    background: transparent;
    height: unset;
    min-width: 320px;
    width: calc((100% - 1.2rem) / 1.5);

    /* increase cursor hover effective area, avoid flickering/flashing of custom cursor hovering on margin between slides */
    &:after {
      content: "";
      right: -1rem;
      top: 0;
      position: absolute;
      width: 1rem;
      height: 100%;
    }

    @media ${device.tablet} {
      max-width: 460px;
      width: calc((100% - 2vw) / 2);
    }

    @media ${device.laptop} {
      width: calc((100% - 2vw) / 3);
    }
  }

  .swiper-scrollbar.swiper-scrollbar-horizontal {
    position: absolute;
    bottom: 2rem;
    left: 0;
    background: ${colors.black};
    height: 10px;
    width: 100%;
    border-radius: 0;
    border: 1px solid ${colors.black};
    border-top: 0;
    border-bottom: 0;
    overflow: hidden;
  }

  .swiper-scrollbar-drag {
    background-color: ${colors.white};
    border-radius: 0;
    height: 8px;
    position: absolute;
    top: 1px;
  }

  @media ${device.laptopL} {
    padding-bottom: 1rem;
  }
`;

export const CarouselBackground = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: ${colors.white};
`;

export const BlackFiller = styled.div`
  width: 100%;
  background: ${colors.black};
`;

export const CardContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;
`;

export const CardImageWrapper = styled.div`
  overflow: hidden;
`;

export const CardImage = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center center;
`;

export const CardContentContainer = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  @media ${device.tablet} {
  }
`;

export const CardTitle = styled.h4`
  ${StyledFonts.mainH6};
  color: ${colors.white};
  background: ${colors.black};
  padding: 1.6rem;
  display: flex;
  align-items: center;
  min-height: 100px;

  .type-collector & {
    min-height: 80px;
  }

  @media ${device.tablet} {
    ${StyledFonts.mainH6};
    padding: 2rem;
    height: 125px;

    .type-collector & {
      height: 90px;
    }
  }

  [lang="th-th"] & {
    line-height: 1.05;
  }
`;

export const CardDescriptionWrapper = styled.div`
  color: ${colors.black};
  border-right: 1px solid ${colors.black};
  border-bottom: 1px solid ${colors.black};
  flex-grow: 1;
  padding-bottom: 1rem;
`;

export const CardDescription = styled.p`
  padding: 2rem 1.6rem;
  margin: 0;
`;

export const CardListSectionWrapper = styled.div`
  padding: 0 1.6rem 2rem;
  font-size: 1.2rem;
  font-weight: 400;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.6rem;

  &.has-title {
    gap: 1.6rem;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    &.has-title {
      gap: 1rem;
    }
  }
`;

export const CardListWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  height: fit-content;

  li {
    display: list-item;
    list-style: outside square;
    margin-left: 1em;
    padding-inline-start: 1rem;

    @media ${device.safari} {
      margin-left: 1.5em;
    }
  }

  @media ${device.tablet} {
    gap: 0.6rem;
  }
`;

export const CardListTitle = styled.p`
  text-transform: uppercase;
  font-size: 1.2rem !important;
`;
