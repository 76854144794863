import React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import AlertMessage from "../AlertMessage";
import { Wrapper, ModalLink } from "./style";
import { useAppDispatch } from "../../store/index";
import { toggleModal, setModalType, setModalState } from "../../store/slice/modalSlice";

const Disclaimer: React.FC = ({}) => {
  const { t } = useTranslation("translations");
  const disclaimerText1 = t("disclaimer.text1");
  const disclaimerText2 = t("disclaimer.text2");
  const disclaimerText3 = t("disclaimer.text3");

  const dispatch = useAppDispatch();
  const openShippingModal = () => {
    dispatch(
      setModalState({
        isOpen: true,
        type: "shippingModal",
      }),
    );
  };

  return (
    <Wrapper>
      <AlertMessage isActive={true} textColor="white">
        {" "}
        {disclaimerText1}&nbsp;
        <ModalLink onClick={openShippingModal} className="ga-shipping-info-modal">
          {disclaimerText2}
        </ModalLink>
        {disclaimerText3}
      </AlertMessage>
    </Wrapper>
  );
};

export default Disclaimer;
