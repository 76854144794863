import styled from "styled-components";
import { device, colors, main_theme } from "../Layout/theme";

export const Wrapper = styled.div`
  background: ${colors.blue};
  position: fixed;
  top: ${main_theme.riotbar_height};
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 1.2rem;
  z-index: ${main_theme.topbar_z_index};
  transition: transform 0.3s;

  &.hidden {
    transform: translateY(calc(-100% - ${main_theme.riotbar_height}));
  }

  @media ${device.tablet} {
    padding: 0.7rem 2.4rem;
  }
`;

export const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
`;

export const MobileWrapper = styled.div`
  display: block;

  @media ${device.tablet} {
    display: none;
  }
`;

export const DesktopWrapper = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;

  [lang="de-de"] & {
    width: 85%;
  }

  @media ${device.tablet} {
    width: 75%;

    [lang="de-de"] & {
      width: 75%;
    }
  }

  a {
    margin-left: 1rem;
    height: 100%;
    display: flex;

    &:first-of-type {
      margin-left: 0;
    }

    @media ${device.tablet} {
      margin-left: 1.2rem;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
`;
