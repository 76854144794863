import React, { useRef, useEffect } from "react";
import parse from "html-react-parser";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import {
  Wrapper,
  ImageWrapper,
  Image,
  ContentWrapper,
  TitleWrapper,
  Title,
  CTAWrapper,
  Price,
  ButtonWrapper,
} from "./style";
import FillLeftButton from "../FillLeftButton";
import CheckoutButton from "../CheckoutButton";
import Disclaimer from "../Disclaimer";

interface Params {
  productType: "collector" | "digital"; // object key for querying data
}

const ProductCTASection: React.FC<Params> = ({ productType }) => {
  const { language } = useI18next();
  const { t } = useTranslation("translations");
  const sectionRef = useRef<HTMLDivElement>(null);

  const localizedInfo = {
    title: t(`productCtaSection.${productType}.title`),
    price: t(`productCtaSection.${productType}.price`),
    imgAlt: t(`productCtaSection.${productType}.image.alt`),
    ctaTextBuy: t(`productCtaSection.${productType}.cta.text.buy`),
    ctaTextSoldOut: t(`productComparison.${productType}.cta.text.soldout`),
    ctaLink: t(`productCtaSection.${productType}.cta.link`),
  };

  const buttonText = true ? localizedInfo.ctaTextBuy : localizedInfo.ctaTextSoldOut;

  const gaClass = `ga-cta-section ga-${productType}-edition-cta-section`;

  // NOTE: image reveal animation
  useEffect(() => {
    if (!sectionRef?.current) return;

    const observerOptions = {
      threshold: 0.6,
      // rootMargin: "-10px 0px -10px 0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);
    observer.observe(sectionRef.current);
  }, [sectionRef]);
  const isAnimatedClass = true ? "animated-zoom" : "";

  return (
    <Wrapper className={`${productType} ${gaClass}`} ref={sectionRef}>
      <ImageWrapper>
        <Image
          src={`/assets/product-cta/${productType}-edition.jpg`}
          alt={localizedInfo.imgAlt}
          loading="eager"
          style={{
            width: "100%",
            height: "100%",
          }}
          className={isAnimatedClass}
        />
      </ImageWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <Title> {parse(localizedInfo.title)} </Title>
        </TitleWrapper>
        <CTAWrapper>
          <Price> {localizedInfo.price} </Price>
          <ButtonWrapper>
            <CheckoutButton text={buttonText} productType={productType} />
          </ButtonWrapper>
        </CTAWrapper>
        {productType == "collector" && <Disclaimer />}
      </ContentWrapper>
    </Wrapper>
  );
};

export default ProductCTASection;
