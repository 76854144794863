import React from "react";
import parse from "html-react-parser";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { Wrapper, TitleWrapper, BottomWrapper, TaglineWrapper, WorldsLogoWrapper } from "./style";
import Media from "../Media";
import Eyebrow from "../Eyebrow";

const Hero: React.FC = () => {
  const { language } = useI18next();
  const { t } = useTranslation("translations");

  const heroImgUrl = `/localized-assets/hero/hero-video-img.jpg`;
  const heroVideoUrl = `/localized-assets/hero/hero-video.mp4`;
  const heroMobileImgUrl = `/localized-assets/hero/hero-video-img-sm.jpg`;
  const heroMobileVideoUrl = `/localized-assets/hero/hero-video-sm.mp4`;

  return (
    <Wrapper className="ga-hero">
      {/* top */}
      <Eyebrow textLeft={t("hero.eyebrow.left")} textCenter={t("hero.eyebrow.center")} textRight="2023" type="center" />
      <TitleWrapper> {parse(t("hero.title"))} </TitleWrapper>

      {/* center */}
      <Media
        imgUrl={heroImgUrl}
        videoUrl={heroVideoUrl}
        fillUpContainer={true}
        className="hero-video display-desktop"
      />
      <Media
        imgUrl={heroMobileImgUrl}
        videoUrl={heroMobileVideoUrl}
        fillUpContainer={true}
        className="hero-video display-mobile"
      />

      {/* bottom */}
      <BottomWrapper>
        <TaglineWrapper>
          <StaticImage
            src="../../images/logo/the-glory-stacked-tagline-white.png"
            alt="The Grind, The Glory"
            loading="eager"
            style={{
              width: "100%",
            }}
          />
        </TaglineWrapper>
        <WorldsLogoWrapper>
          <StaticImage
            src="../../images/logo/logo-worlds-23-white.png"
            alt="Worlds Unlocked 2023"
            loading="eager"
            style={{
              width: "100%",
            }}
          />
        </WorldsLogoWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

export default Hero;
