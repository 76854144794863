import React, { useState } from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { Wrapper, TitleWrapper, Title, Subtitle, ContentWrapper } from "./style";
import Accordion from "../Accordion";
import { shippingCountries } from "../../data/shippingCountryInfo";

const FAQSection: React.FC = () => {
  const { language } = useI18next();
  const { t } = useTranslation("translations");
  // first accordion open by default
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // generate shipping country list
  const getCountryListHTML = () => {
    let countryListHTML = "<ul class='country-list'>";
    shippingCountries.forEach((country) => {
      countryListHTML += `<li class='country-list-item'>${t(country)}</li>`;
    });
    countryListHTML += "</ul>";
    return countryListHTML;
  };
  const countryListHTML = getCountryListHTML();
  const countryAnswerIndex = 3;

  // filter questions
  const getQuestionList = () => {
    const totalCount = t("faq.qna.total");
    if (!totalCount || isNaN(Number(totalCount))) {
      console.log("Need faq total number in translations.json");
      return [];
    }
    const questionList = [];
    for (let i = 1; i <= Number(totalCount); i++) {
      let answer = t(`faq.qna.q${i}.answer`);
      let currentQuestion = {
        question: t(`faq.qna.q${i}.question`),
        answer: i == countryAnswerIndex ? answer.replace("<span>", countryListHTML) : answer,
      };
      questionList.push(currentQuestion);
    }
    return questionList;
  };
  const localizedQuestionList = getQuestionList();

  const gaClass = "ga-faq-section";

  return (
    <Wrapper id="faq-section" className={gaClass}>
      <TitleWrapper>
        <Title> {t("faq.title")} </Title>
        <Subtitle> {t("faq.subtitle")}</Subtitle>
      </TitleWrapper>
      <ContentWrapper>
        {localizedQuestionList &&
          localizedQuestionList.map((question, index) => (
            <Accordion
              key={index}
              index={index}
              topText={question.question}
              bottomText={question.answer}
              ariaLabel="qna"
              isActive={activeIndex == index}
              setActiveIndex={setActiveIndex}
            />
          ))}
      </ContentWrapper>
    </Wrapper>
  );
};

export default FAQSection;
