import React from "react";
import { EyebrowWrapper, EyebrowLeft, EyebrowCenter, EyebrowRight } from "./style";

interface Params {
  textLeft: string;
  textCenter?: string;
  textRight: string;
  type?: "center" | "numbered" | "splitted";
}

const Eyebrow: React.FC<Params> = ({ textLeft, textCenter, textRight, type = "numbered" }) => {
  return (
    <EyebrowWrapper className={type}>
      <EyebrowLeft> {textLeft} </EyebrowLeft>
      <EyebrowCenter> {type == "splitted" ? "—" : textCenter} </EyebrowCenter>
      <EyebrowRight> {textRight} </EyebrowRight>
    </EyebrowWrapper>
  );
};

export default Eyebrow;
