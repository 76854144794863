import React, { useEffect } from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { Wrapper, RightSide, LeftSide, MobileWrapper, DesktopWrapper } from "./style";
import { StaticImage } from "gatsby-plugin-image";
import FillUpButton from "../FillUpButton";
import { AllProductType } from "../../helpers/types/commonTypes";

import { checkIsCurrentLocaleEuropeRegion } from "../../data/localeRegionMap";
import { setUserIsEurope } from "../../store/slice/userSlice";
import { useAppDispatch } from "../../store";
import useGeoLocation from "../../hooks/useGeoLocation";

interface Params {
  productType: AllProductType;
}

const TopBar: React.FC<Params> = ({ productType }) => {
  const { language } = useI18next();
  const { t } = useTranslation("translations");

  // IMPORTANT NOTE:
  // update user isEurope for shopify checkout based on language/locale
  // put here as it will only run once, react-redux need to be inside Layout component to run
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (language) {
      const isEurope = checkIsCurrentLocaleEuropeRegion(language);
      dispatch(setUserIsEurope(isEurope));
    }
  }, [language]);

  // get geolocation from netlify
  useGeoLocation();

  return (
    <Wrapper className="topbar ga-subnav">
      <RightSide>
        <MobileWrapper>
          <StaticImage
            src="../../images/logo/logo-worlds-23-short-white.png"
            alt=""
            loading="eager"
            style={{
              width: "100%",
              maxWidth: "30px",
            }}
          />
        </MobileWrapper>
        <DesktopWrapper>
          <StaticImage
            className="logo-desktop"
            src="../../images/logo/logo-worlds-23-white.png"
            alt=""
            loading="eager"
            style={{
              width: "100%",
              maxWidth: "128px",
            }}
          />
        </DesktopWrapper>
      </RightSide>
      <LeftSide>
        <FillUpButton
          link="#collector-edition-overview"
          text={t("topBar.productCta.collector.text")}
          gaTracking="ga-nav-collector-edition-btn ga-nav-btn"
        />
        <FillUpButton
          link="#digital-edition-overview"
          text={t("topBar.productCta.digital.text")}
          gaTracking="ga-nav-digital-edition-btn ga-nav-btn"
        />
        <FillUpButton
          link="#faq-section"
          text={t("topBar.productCta.faq.text")}
          gaTracking="ga-nav-digital-edition-btn ga-nav-btn"
        />
      </LeftSide>
    </Wrapper>
  );
};

export default TopBar;
