import React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { Wrapper, ContentWrapper, DisclaimerWrapper } from "./style";
import ProductInfoCard from "../ProductInfoCard";
import { AllProductType } from "../../helpers/types/commonTypes";
import Disclaimer from "../Disclaimer";

interface Params {
  fullVersion: boolean; // true => comparison, false => cta section
  productType: AllProductType; // object key for querying data
}

const ProductComparison: React.FC<Params> = ({ productType, fullVersion }) => {
  const { language } = useI18next();
  const { t } = useTranslation("translations");

  const getProductInfoCardArray = () => {
    if (productType == "all") return ["collector", "digital"];
    return [productType];
  };

  const productInfoCardArray = getProductInfoCardArray();

  const gaSectionClass = fullVersion
    ? "ga-comparison-full-version ga-comparison"
    : "ga-comparison-cta-version ga-comparison";

  return (
    <Wrapper className={`${fullVersion ? "full-version" : "cta-version"} ${gaSectionClass}`}>
      <ContentWrapper className={`${productType}-version`}>
        {/* product cards */}
        {productInfoCardArray &&
          productInfoCardArray.map((productInfoKey, index) => (
            <ProductInfoCard
              key={productInfoKey}
              productType={productInfoKey as "collector" | "digital"}
              fullVersion={fullVersion}
            />
          ))}

        {/* disclaimer */}
        <DisclaimerWrapper className="mobile">
          <Disclaimer />
        </DisclaimerWrapper>
      </ContentWrapper>

      <DisclaimerWrapper className="desktop">
        <Disclaimer />
      </DisclaimerWrapper>
    </Wrapper>
  );
};

export default ProductComparison;
