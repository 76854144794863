import styled, { css } from "styled-components";
import { device, colors, main_theme, transitions, StyledFonts, fixedSidePadding } from "../Layout/theme";
import topBgTexture from "../../images/introduction/intro-top-bg-texture.png";
import bottomBgTexture from "../../images/introduction/intro-bottom-bg-texture.png";

export const Wrapper = styled.div`
  padding-top: 6.4rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;

    background: url(${topBgTexture});
    aspect-ratio: 3 / 2;
  }

  @media ${device.tablet} {
    padding-top: 13rem;

    &::before {
      max-height: 500px;
    }
  }
`;

export const TopWrapper = styled.div``;

export const EyebrowWrapper = styled.div`
  display: grid;
  place-items: center;
  ${fixedSidePadding};

  &.bottom {
    padding-top: 6rem;
    padding-bottom: 4rem;

    @media ${device.tablet} {
      padding-top: 10rem;
      padding-bottom: clamp(6rem, 6vw, 13rem);
    }
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 94vw;

  @media ${device.tablet} {
    padding-top: 4vw;
    width: 94vw;
    max-width: 1340px;
  }

  @media ${device.laptop} {
    width: 90vw;
  }

  @media ${device.desktopM} {
    max-width: 1600px;
  }
`;

export const Title = styled.h2`
  ${StyledFonts.mainH2};
  text-align: center;
  padding-bottom: 4rem;

  @media ${device.tablet} {
    padding-bottom: clamp(4rem, 6vw, 8rem);
    /* max-width: 1600px; */

    [lang="id-id"] &,
    [lang="pl-pl"] &,
    [lang="pt-br"] &,
    [lang="ro-ro"] &,
    [lang="tr-tr"] &,
    [lang="th-th"] & {
      font-size: clamp(5rem, 7vw, 9.5rem);
    }
  }
`;

export const Subtitle = styled.h4`
  ${StyledFonts.mainH7};
  text-align: center;
  padding-bottom: 6rem;
  text-transform: uppercase !important;

  @media ${device.laptop} {
    padding-bottom: 0;
  }
`;

const CommonImageWrapperStyle = css`
  display: flex;
  flex-direction: row;

  .bg-image {
    position: relative;
    overflow: hidden;

    &::before {
      position: absolute;
      content: "";
      inset: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: var(--blend-mode);
      z-index: 1;
    }

    &.animated-zoom {
      picture {
        transform: scale(1.15);
        transition: transform 1s ${transitions.imgCubicBezier1};

        .in-view & {
          transform: scale(1);
        }
      }
    }
  }
`;

export const LeftImageWrapper = styled.div`
  display: none;
  z-index: -1;
  width: 30%;

  .bg-image {
    object-fit: cover;
    overflow: hidden;

    &.animated-zoom {
      picture {
        transform: scale(1.15);
        transition: transform 1s ${transitions.imgCubicBezier1};

        .in-view & {
          transform: scale(1);
        }
      }
    }
  }

  @media ${device.tablet} {
    ${CommonImageWrapperStyle};
    display: block;
    position: absolute;
    bottom: 52%;
    left: 0;
  }
`;

export const RightImageWrapper = styled.div`
  display: none;
  align-self: flex-end;
  z-index: -1;
  width: 65%;

  .bg-image {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    overflow: hidden;

    &:first-of-type {
      margin-right: 0.5rem;
    }

    &.animated-zoom {
      img {
        transform: scale(1.15);
        transition: transform 1s ${transitions.imgCubicBezier1};

        .in-view & {
          transform: scale(1);
        }
      }
    }
  }

  @media ${device.tablet} {
    ${CommonImageWrapperStyle}
    width: 40%;
    position: absolute;
    top: 8%;
    right: 0;

    .bg-image {
      aspect-ratio: 18 / 20;

      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }

  @media ${device.laptop} {
    margin-top: -2rem;
  }
`;

export const BottomWrapper = styled.div`
  ${fixedSidePadding};
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: url(${bottomBgTexture});
    background-size: contain;
    z-index: -1;
    aspect-ratio: 18 / 5;
  }

  @media ${device.laptop} {
    padding-top: 8rem;
  }
`;

export const InfoWrapper = styled.div`
  width: 80vw;
  max-width: 690px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: clamp(4rem, 5vw, 5rem);

  @media ${device.tablet} {
    text-align: left;
  }
`;

export const InfoDescription = styled.p`
  text-align: center;
`;
