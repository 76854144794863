import React, { useEffect, useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { colors } from "../Layout/theme";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import {
  Wrapper,
  EyebrowWrapper,
  TopWrapper,
  LeftImageWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  RightImageWrapper,
  ContentWrapper,
  BottomWrapper,
  InfoWrapper,
  InfoDescription,
} from "./style";
import Eyebrow from "../Eyebrow";
import { AllProductType } from "../../helpers/types/commonTypes";

interface Params {
  productType: AllProductType;
}

const Introduction: React.FC<Params> = ({ productType }) => {
  const { language } = useI18next();
  const { t } = useTranslation("translations");

  const gaClass = "ga-explainer-module";

  // NOTE: image reveal animation
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!containerRef?.current) return;

    const observerOptions = {
      threshold: 0.4,
      // rootMargin: "-10px 0px -10px 0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);
    observer.observe(containerRef.current);
  }, [containerRef]);

  return (
    <Wrapper className={`type-${productType} ${gaClass} introduction`} ref={containerRef}>
      <TopWrapper>
        <EyebrowWrapper className="eyebrow">
          <Eyebrow
            textLeft={t("introduction.eyebrow.left")}
            textRight={t("introduction.eyebrow.right")}
            type="splitted"
          />
        </EyebrowWrapper>

        <ContentWrapper>
          <LeftImageWrapper>
            <StaticImage
              quality={100}
              src="../../images/introduction/intro-left-image-1.jpg"
              alt="Worlds 23 Product Image"
              className="bg-image animated-zoom"
            />
          </LeftImageWrapper>

          <TitleWrapper className="title-wrapper text">
            <Title className="title">{parse(t("introduction.title"))}</Title>
            <Subtitle className="title">{t("introduction.subtitle")}</Subtitle>
          </TitleWrapper>

          <RightImageWrapper>
            <StaticImage
              src="../../images/introduction/intro-right-image-1.jpg"
              alt="Worlds 23 Product Image"
              className="bg-image animated-zoom"
              style={{ opacity: "0.4" }}
            />
            <StaticImage
              src="../../images/introduction/intro-right-image-2.jpg"
              alt="Worlds 23 Product Image"
              className="bg-image animated-zoom"
              style={{ opacity: "0.4" }}
            />
          </RightImageWrapper>

          <BottomWrapper className="bottom-wrapper">
            <InfoWrapper>
              <InfoDescription className="text bottom-text">{parse(t("introduction.description"))}</InfoDescription>
            </InfoWrapper>
            <EyebrowWrapper className="bottom  bottom-text">
              <Eyebrow
                textLeft={t("introduction.date.left")}
                textRight={t("introduction.date.right")}
                type="splitted"
              />
            </EyebrowWrapper>
          </BottomWrapper>
        </ContentWrapper>
      </TopWrapper>
    </Wrapper>
  );
};

export default Introduction;
