import { useEffect, useState } from "react";

// used to fix Client-side UI not matching with SSR
export default function useIsClient() {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient;
}
