import React, { useState } from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { useAppSelector, useAppDispatch } from "../../store/index";
import parse from "html-react-parser";
import {
  Wrapper,
  TopWrapper,
  TitleWrapper,
  Title,
  AssetWrapper,
  ProductDetailWrapper,
  ProductFeatureWrapper,
  ProductFeatureList,
  ProductFeature,
  ProductCTA,
  PriceWrapper,
  Price,
  CtaWrapper,
} from "./style";
import Media from "../Media";
import CheckoutButton from "../CheckoutButton";
import AlertMessage from "../AlertMessage";

interface Params {
  productType: "collector" | "digital";
  fullVersion: boolean;
}

const ProductInfoCard: React.FC<Params> = ({ productType, fullVersion }) => {
  const { language } = useI18next();
  const { t } = useTranslation("translations");
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);

  const hasLogin = useAppSelector((state) => state.user.isAuthenticated); // from rso login or Riotbar function

  const localizedInfo = {
    title: t(`productComparison.${productType}.title`),
    img: `/assets/product-comparison/${productType}-edition.jpg`,
    mobileImg: `/assets/product-comparison/${productType}-edition-sm.jpg`,
    imgAlt: t(`productComparison.${productType}.image.alt`),
    price: t(`productComparison.${productType}.price`),
    featureTotal: t(`productComparison.${productType}.features.total`),
    ctaTextBuy: t(`productComparison.${productType}.cta.text.buy`),
    ctaTextSoldOut: t(`productComparison.${productType}.cta.text.soldout`),
    ctaLink: t(`productComparison.${productType}.cta.link`),
  };

  // filter product features
  const getProductFeatureList = () => {
    const featureListTotal = 2; // default 2 list
    let localizedFeatureLists = [];

    for (let i = 1; i <= Number(featureListTotal); i++) {
      let currentFeatureList = t(`productComparison.${productType}.features.list_${i}`);
      let currentFeatureListArray = currentFeatureList.split(";");
      localizedFeatureLists.push(currentFeatureListArray);
    }
    return localizedFeatureLists;
  };
  const localizedFeatureLists = getProductFeatureList();

  const getCollectorMobileProductFeatureList = () => {
    const featureListTotal = 2; // default 2 list
    let localizedFeatureLists = [];

    for (let i = 1; i <= Number(featureListTotal); i++) {
      let currentFeatureList = t(`productComparison.${productType}.features.list_${i}.mobile`);
      let currentFeatureListArray = currentFeatureList.split(";");
      localizedFeatureLists.push(currentFeatureListArray);
    }
    return localizedFeatureLists;
  };
  const localizedCollectorMobileFeatureLists = getCollectorMobileProductFeatureList();

  // based on shopify "available for sale" field set in product
  const productAvailabiltyObject = useAppSelector((state) => state.product);
  const isEurope = useAppSelector((state) => state.user.isEurope);

  // availablility check
  const getCurrentProductAvailabilty = () => {
    if (isEurope) {
      return productType == "digital"
        ? productAvailabiltyObject.isEuDigitalEditionAvailable
        : productAvailabiltyObject.isEuCollectorEditionAvailable;
    } else {
      // NA store
      return productType == "digital"
        ? productAvailabiltyObject.isNaDigitalEditionAvailable
        : productAvailabiltyObject.isNaCollectorEditionAvailable;
    }
  };
  // disable ask user login on hover
  const isProductAvailable = getCurrentProductAvailabilty();
  const hoverAbleClass = isProductAvailable ? "" : "soldout";

  return (
    <Wrapper className={`product-card-${productType}`}>
      <TopWrapper>
        <TitleWrapper>
          <Title>{parse(localizedInfo.title)}</Title>
        </TitleWrapper>
        <AssetWrapper>
          {/* see if needs to update props with video if there's one in future */}
          <Media imgUrl={localizedInfo.img} imgAlt={localizedInfo.imgAlt} type="display-desktop" />
          <Media imgUrl={localizedInfo.mobileImg} imgAlt={localizedInfo.imgAlt} type="display-mobile" />
        </AssetWrapper>
      </TopWrapper>
      <ProductDetailWrapper>
        {fullVersion && (
          <ProductFeatureWrapper>
            {/* desktop view */}
            {localizedFeatureLists.map((featureList, index) => (
              <ProductFeatureList className={`${productType} list-${index + 1} desktop`} key={`feature-list-${index}`}>
                {featureList.map((feature, i) => (
                  <ProductFeature key={`feature-${i}`}>{feature}</ProductFeature>
                ))}
              </ProductFeatureList>
            ))}

            {/* mobile collector view: shorten with 'All digital items' :') */}
            {productType == "collector" &&
              localizedCollectorMobileFeatureLists.map((featureList, index) => (
                <ProductFeatureList className={`${productType} list-${index + 1} mobile`} key={`feature-list-${index}`}>
                  {featureList.map((feature, i) => (
                    <ProductFeature key={`feature-${i}`}>{feature}</ProductFeature>
                  ))}
                </ProductFeatureList>
              ))}
          </ProductFeatureWrapper>
        )}

        <ProductCTA>
          <PriceWrapper>
            <Price>{localizedInfo.price}</Price>
          </PriceWrapper>
          <CtaWrapper
            className={hoverAbleClass}
            onMouseEnter={() => setShowAlertMessage(true)}
            onMouseLeave={() => setShowAlertMessage(false)}
          >
            <CheckoutButton text={localizedInfo.ctaTextBuy} type="black" productType={productType} />
          </CtaWrapper>
        </ProductCTA>
        <AlertMessage isActive={showAlertMessage && !hasLogin} animated={true}>
          {t("messages.alert.login")}
        </AlertMessage>
      </ProductDetailWrapper>
    </Wrapper>
  );
};

export default ProductInfoCard;
