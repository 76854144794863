import styled from "styled-components";
import { device, colors, transitions, StyledFonts } from "../Layout/theme";

export const ButtonText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: none;
  }

  @media ${device.tablet} {
    font-size: 1.6rem;

    svg {
      display: inline-block;
      width: 1.4rem;
      margin-left: 1.2rem;
    }
  }
`;

export const ButtonWrapper = styled.a`
  cursor: pointer;
  ${StyledFonts.subheading1};
  color: ${colors.white};
  background: transparent;
  display: inline-block;
  height: fit-content;
  padding: 0.8rem 0.4rem 0.7rem;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid ${colors.white};
  transition: ${transitions.allCubicBezier1};

  &:before {
    background: ${colors.white};
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    transition: ${transitions.allCubicBezier1};
    z-index: -1;
  }

  &:hover {
    color: ${colors.blue};

    &::before {
      transform: translateY(0);
    }
  }

  @media ${device.tablet} {
    padding: 1rem 1.5rem 0.9rem;
  }
`;
