import styled from "styled-components";
import { device, colors, transitions, StyledFonts } from "../Layout/theme";

export const AccordionWrapper = styled.div`
  width: 100%;
`;

export const AccordionButton = styled.button`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 44px;
  text-align: left;
  position: relative;
  background: ${colors.black};
  overflow: hidden;

  &:before {
    content: "";
    background: ${colors.blue};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    transition: ${transitions.allCubicBezier1};
    z-index: 0;
  }

  &:hover {
    &::before {
      transform: translateY(0);
      transition: all 0.8s cubic-bezier(0.22, 1, 0.19, 1);
    }
  }
`;

export const AccordionButtonText = styled.h4`
  ${StyledFonts.subheading1};
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: ${colors.white};
  z-index: 1;

  padding: 2rem 3rem 2rem 1.2rem;
  min-height: 9rem;
  border-top: 1px solid ${colors.white};
  /* border-bottom: 1px solid ${colors.black}; */
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    padding: 3.6rem 4rem 3.6rem 2.5rem;
  }
`;

export const AccordionButtonControl = styled.div`
  background: ${colors.white};
  color: ${colors.black};
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  /* border-bottom: 1px solid ${colors.black}; */
  z-index: 1;

  svg {
    width: 30px;
    transform: rotate(45deg);
    transition: ${transitions.allCubicBezier1};
  }

  .active & {
    /* border-bottom: 1px solid ${colors.black}; */

    svg {
      transform: rotate(0deg);
    }
  }
`;

export const AccordionContent = styled.div`
  /* moved padding to p as padding will still show up when padding zero */
  background: ${colors.white};
  color: ${colors.black};
  /* border-bottom: 1px solid ${colors.black}; */
  box-sizing: border-box;
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &.active {
    max-height: 200px;

    :has(.long-answer) {
      max-height: 650px;
    }

    :has(.two-column) {
      max-height: 300px;
    }

    @media ${device.tablet} {
      max-height: 450px;
    }
  }
`;

export const AccordionContentText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  width: 100%;
  padding: 3.2rem 2.4rem 3.2rem 1.2rem;

  @media ${device.tablet} {
    font-size: 1.4rem !important;
    max-width: 800px;
    padding: 3.6rem 4rem 4.2rem 2.5rem;
  }

  @media ${device.laptop} {
    max-width: 1100px;
  }

  &.two-column {
    @media ${device.tablet} {
      column-count: 2;
      column-gap: 2.5rem;
    }
  }

  &.long-answer {
    ul {
      list-style: square outside;
      margin-left: 1em;
      padding-inline-start: 1rem;
      font-size: 1.2rem;
      padding-top: 1.6rem;
      column-count: 2;

      li {
        display: list-item;
        list-style: square outside;
        padding-bottom: 0.5rem;
      }
    }

    @media ${device.tablet} {
      ul {
        column-count: 3;

        li {
          font-size: 1.4rem;
        }
      }
    }
  }
`;
