import { graphql } from "gatsby";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// assets
import FullImage1 from "../images/full-media-section/full-image-separator-1.jpg";
import FullImage2 from "../images/full-media-section/full-image-separator-2.jpg";
import FullImage3 from "../images/full-media-section/full-image-separator-3.jpg";
import MobileFullImage1 from "../images/full-media-section/full-image-separator-1-sm.jpg";
import MobileFullImage2 from "../images/full-media-section/full-image-separator-2-sm.jpg";
import MobileFullImage3 from "../images/full-media-section/full-image-separator-3-sm.jpg";
// components
import TopBar from "../components/TopBar";
import Hero from "../components/Hero";
import ProductComparison from "../components/ProductComparison";
import Marquee from "../components/Marquee";
import FullMediaSeparator from "../components/FullMediaSeparator";
import ProductFeatures from "../components/ProductFeatures";
import ProductCTASection from "../components/ProductCTASection";
import ProductOverview from "../components/ProductOverview";
import FAQSection from "../components/FAQSection";
import Introduction from "../components/Introduction";
import LoadInAnimation from "../components/LoadInAnimation";

const IndexPage: React.FC = ({}) => {
  const { language } = useI18next();
  const { t } = useTranslation("translations");
  const productType = "all"; // show both editions at all times

  return (
    <Layout>
      <GatsbySeo
        title={t("siteMetadataTitle")}
        description={t("siteMetadataDescription")}
        openGraph={{
          title: t("siteMetadataTitle"),
          description: t("siteMetadataDescription"),
          images: [{ url: `/site-image.jpg` }],
          site_name: t("siteMetadataTitle"),
        }}
      />
      <TopBar productType={productType} />

      {/* Top Sections */}
      <Hero />
      <ProductComparison productType={productType} fullVersion={false} />
      <Introduction productType={productType} />
      <Marquee text={t("marquee.text")} />

      {/* Product Detail: Collector's Edition */}
      {/* showCollectorEdition && */}
      <ProductOverview productType="collector" />
      <ProductFeatures productType="collector" />
      <ProductCTASection productType="collector" />
      <FullMediaSeparator
        imgUrl={FullImage1}
        mobileImgUrl={MobileFullImage1}
        imgAlt={t("fullImage_1.alt")}
        videoUrl=""
      />

      {/* Product Detail: Digital Edition */}
      {/* showDigitalEdition && */}
      <ProductOverview productType="digital" />
      <ProductFeatures productType="digital" />
      <ProductCTASection productType="digital" />
      <FullMediaSeparator
        imgUrl={FullImage2}
        mobileImgUrl={MobileFullImage2}
        imgAlt={t("fullImage_2.alt")}
        videoUrl=""
      />

      <Marquee text={t("marquee.text")} />

      {/* FAQ */}
      <FAQSection />
      <ProductComparison productType={productType} fullVersion={true} />
      <FullMediaSeparator
        imgUrl={FullImage3}
        mobileImgUrl={MobileFullImage3}
        imgAlt={t("fullImage_3.alt")}
        videoUrl=""
      />

      <LoadInAnimation />
    </Layout>
  );
};
export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    ...TranslationQueryFragment
  }
`;
