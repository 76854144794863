import styled from "styled-components";
import {
  colors,
  StyledFonts,
  fixedSidePadding,
  gridSystem,
  main_theme,
  device,
  CancelOffLineHeightForEN,
} from "../Layout/theme";

export const Wrapper = styled.div`
  padding: 20px;
  ${fixedSidePadding};

  width: 100%;
  min-height: calc(100vh - ${main_theme.riotbar_height} - ${main_theme.topbar_height});
  display: flex;
  flex-direction: column;

  .video-wrapper {
    flex-grow: 1;
  }

  @media ${device.tablet} {
    .video-wrapper {
      min-height: 580px;
    }
  }
`;

export const TitleWrapper = styled.h1`
  ${StyledFonts.mainH1};
  margin-bottom: 0.4rem;
  text-transform: uppercase !important;

  @media ${device.laptopL} {
    font-size: clamp(7rem, 6.2vw, 9.6rem);
  }

  @media (min-width: 1840px) {
    font-size: clamp(7rem, 6.2vw, 16rem);
  }

  ${CancelOffLineHeightForEN};

  [lang="ru-ru"] & {
    line-height: 0.9;
    margin-bottom: 0.2em;
  }

  [lang="tr-tr"] & {
    margin-bottom: 0.2em;
  }

  [lang="ja-jp"] &,
  [lang="el-gr"] & {
    font-family: ${main_theme.font_family_heading};
    font-stretch: normal;
    font-weight: 400;
    line-height: 0.83;
    letter-spacing: 1;
    margin-bottom: 0.05em;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 1.6rem;

  @media ${device.tablet} {
    margin: 1rem 0 3.6rem;
  }
`;

export const TaglineWrapper = styled.div`
  width: clamp(120px, 20vw, 220px);
`;

export const WorldsLogoWrapper = styled.div`
  width: clamp(105px, 16vw, 180px);
`;
