import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import {
  AccordionWrapper,
  AccordionButton,
  AccordionButtonText,
  AccordionButtonControl,
  AccordionContent,
  AccordionContentText,
} from "./style";
import AccordionClose from "../../images/svg/close.svg";
import useIsClient from "../../hooks/use-is-client";

interface Params {
  index: number;
  topText: string;
  bottomText: string;
  ariaLabel: string;
  setActiveIndex: (index: number) => void;
  isActive: boolean;
}

const Accordion: React.FC<Params> = ({ index, topText, bottomText, ariaLabel, isActive, setActiveIndex }) => {
  const [contentStyleClass, setContentStyleClass] = useState<string>("");
  const isClient = useIsClient();

  const toggleAccordion = () => {
    // close accordion if current one is active
    return isActive ? setActiveIndex(-1) : setActiveIndex(index);
  };

  useEffect(() => {
    let letterBasedClass = bottomText.includes("country-list")
      ? "long-answer"
      : bottomText.length > 360
      ? "two-column"
      : "";
    setContentStyleClass(letterBasedClass);
  }, []);

  const gaButtonClass = `ga-faq-btn ga-faq-btn-${index + 1}`;

  return (
    <AccordionWrapper key={`qna-${index}`} className={`ga-faq-${index}`}>
      <AccordionButton
        type="button"
        data-expanded={isActive}
        aria-expanded={isActive}
        className={`${gaButtonClass} ${isActive ? "active" : ""}`}
        id={`${ariaLabel}-${index}`}
        onClick={toggleAccordion}
      >
        <AccordionButtonText>{topText}</AccordionButtonText>
        <AccordionButtonControl>
          <AccordionClose />
        </AccordionButtonControl>
      </AccordionButton>
      <AccordionContent
        role="qna"
        data-expanded={isActive}
        aria-expanded={isActive}
        className={isActive ? "active" : ""}
        aria-labelledby={`${ariaLabel}-${index}`}
      >
        {isClient && (
          <AccordionContentText as="div" className={contentStyleClass}>
            {parse(bottomText)}
          </AccordionContentText>
        )}
      </AccordionContent>
    </AccordionWrapper>
  );
};

export default Accordion;
