import styled, { css } from "styled-components";
import { device, colors, transitions, main_theme, StyledFonts } from "../Layout/theme";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  /* &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 2;

    @media ${device.tablet} {
      height: 50%;
    }
  } */
`;

const paddingY = "4.6rem";

export const OverlayText = styled.div`
  ${StyledFonts.subheading1};
  display: none;

  @media ${device.tablet} {
    display: block;
    position: absolute;
    z-index: 3;
  }

  &.top-left {
    top: ${paddingY};
    left: ${main_theme.paddingDesktopX};
  }

  &.top-right {
    top: ${paddingY};
    right: ${main_theme.paddingDesktopX};
  }

  &.bottom-left {
    bottom: ${paddingY};
    left: ${main_theme.paddingDesktopX};
  }

  &.bottom-right {
    right: ${main_theme.paddingDesktopX};
    bottom: ${paddingY};
  }
`;
