const EUregionLocaleList = [
  "en-gb",
  "cs-cz",
  "de-de",
  "el-gr",
  "es-es",
  "fr-fr",
  "it-it",
  "hu-hu",
  "pl-pl",
  "pt-br",
  "ro-ro",
  "tr-tr",
];

export const checkIsCurrentLocaleEuropeRegion = (locale: string) => {
  return EUregionLocaleList.includes(locale) ? true : false;
};
