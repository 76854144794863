import styled from "styled-components";
import { device, colors, fixedSidePadding } from "../Layout/theme";

export const Wrapper = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  background: ${colors.white};
  padding-top: 0.8rem;
  padding-bottom: 2rem;

  .product-card-collector,
  .product-card-digital {
    ${fixedSidePadding};
  }

  .product-card-collector {
    order: 1;
  }
  .product-card-digital {
    order: 3;
  }

  @media ${device.laptop} {
    ${fixedSidePadding};
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;

    .product-card-collector,
    .product-card-digital {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const DisclaimerWrapper = styled.div`
  ${fixedSidePadding};
  padding-top: 1.2rem;
  padding-bottom: 1.4rem;
  display: block;
  width: 100%;
  background: ${colors.black};
  grid-column: span 1;
  order: 2;

  &.mobile {
    display: block;

    .alert-message-wrapper p {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &.desktop {
    display: none;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  @media ${device.laptop} {
    grid-column: span 2;

    &.mobile {
      display: none;
    }
    &.desktop {
      display: block;
    }
  }
`;
